@media screen and (max-width: 888px) {

    .scale-to-mid {
        transform: scale(0.7) !important;
    }

    .scale-to-small {
        flex-direction: row !important;
        font-size: 12px;
        display: flex;
        width: 100%;
        justify-content: space-between !important;
    }

    .mobile-col {
        flex-direction: column !important;
        align-items: start;
        gap: 10px !important;
        font-size: 12px !important;
    }

    .heading-on-low {
        margin-top: 50px !important;
    }

    .h-smal {
        font-size: 10px !important;
    }

    .mobile-knowledge {
        margin-top: 12px !important;
    }

    .mobile-logo-top {
        margin-top: -80px;
    }

    .small-text {
        font-size: 12px !important;
    }


    .smaller-text {
        font-size: 12px !important;
        width: 200% !important;
        margin-left: -50%;
    }

    .helmet-on-small {
        margin-left: 15px;
    }

    .helmet-on-small-r {
        margin-left: 19px;
    }

    .content-on-small {
        width: max-content !important;
    }

    .time-on-small {
        margin-left: 13px;
    }

    .mobile-btn-top {
        margin-top: -30px;
    }

    .mobile-hs {
        font-size: 26px !important;
    }

    .mobile-hide {
        opacity: 0;
        visibility: none;
        display: none;
    }

    .mobile-box {
        background: none !important;
    }

}